<!-- 发票管理-客户发票 -->
<template>
  <div class="pagePadding">
    <div class="table">
      <!-- 客户名称 -->
      <div class="item">
        <div class="title">客户名称</div>
        <div class="body">
          <Select :disabled="readonly" placeholder="请选择" v-model="echoName" @on-change="selectName" clearable filterable class="width300">
            <Option v-for="(item, index) in thirdList" :value="JSON.stringify(item)" :key="index">{{ item.customer_name }}</Option>
          </Select>
        </div>
      </div>
      <!-- 发票单号 -->
      <div class="item" v-if="!this.$route.query.added">
        <div class="title">发票单号</div>
        <div class="body">
          <Input :disabled="readonly" v-model="invoice.invoice_oddnum" class="width300"></Input>
        </div>
      </div>
      <!-- 开票时间 -->
      <div class="item">
        <div class="title">开票时间</div>
        <div class="body">
          <DatePicker :disabled="readonly" :options="limitDate" placeholder="请选择" format="yyyy-MM-dd" class="width300" :value="invoice.billing_time" @on-change="changeTime"></DatePicker>
        </div>
      </div>
      <!-- 发票 -->
      <div class="item">
        <div class="title">发票</div>
        <div class="body">
          <div class="uploading width1000">
            <span v-for="(item, index) in debitNote" :key="index">
              <a @click="openFile(item.invoice_url)">{{ item.invoice_name }}</a>
              <i @click="deleteImage(index)" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="debitNote && debitNote.length == 0">只允许上传jpg,jpeg,png,pdf格式的文件</i>
            <FormUpload class="upload finger" label="上传" :multiple="true" @get-key="getKey(...arguments)">上传</FormUpload>
          </div>
        </div>
      </div>
      <!-- 关联销售订单 -->
      <div class="newItem">
        <div class="title">关联销售订单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in salesList" :key="index">
              <span>{{ item.order_code }}</span>
              <i @click="isDeleteSal2 = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="salesList && salesList.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill2">查询</span>
        </div>
      </div>
      <!-- 关联销售退货单 -->
      <div class="newItem">
        <div class="title">销售退货通知单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in salesReturnList" :key="index">
              <span>{{ item.id }}</span>
              <i @click="isDeleteSal = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="salesReturnList && salesReturnList.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill1">查询</span>
        </div>
      </div>
      <!-- 关联出库单 -->
      <div class="newItem">
        <div class="title"><span>关联出库单</span></div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in outboundOrderList" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeleteWare = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="outboundOrderList && outboundOrderList.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill">查询</span>
        </div>
      </div>
      <!-- 关联销售订单 -->
      <div class="newItem">
        <div class="title">关联销售退货单</div>
        <div class="body">
          <div class="uploading width1000 fl">
            <span class="itemSpan" v-for="(item, index) in returnGoods" :key="index">
              <span>{{ item.order_number }}</span>
              <i @click="isDeleteSal3 = [true, index]" v-if="!readonly">x</i>
            </span>
            <i class="tip" v-if="returnGoods && returnGoods.length == 0">请选择</i>
          </div>
          <span class="pageBtn finger btnSure marginLeft20 fl" v-if="!readonly" @click="addBill3">查询</span>
        </div>
      </div>
      <!-- 发票金额 -->
      <div class="item borderBtm">
        <div class="title">发票金额</div>
        <div class="body">
          <Input :disabled="readonly" readonly class="width300" v-model="invoice.invoice_money" />
          <span style="margin-left: 8px;">元</span>
        </div>
      </div>
    </div>
    <div class="pageBtm fr">
      <span class="pageBtn finger btnReset" @click="back">返回</span>
      <span class="pageBtn finger btnSure marginLeft20" v-if="!readonly" @click="beforeCommit">提交</span>
    </div>

    <!-- 确认提交-弹窗 -->
    <tips-component showModal="post" v-if="win" @cancleBtn="win = false" @sureBrn="commit"></tips-component>

    <!-- 确认删除-弹窗 -->
    <tips-component showModal="delete" v-if="isDeleteWare[0]" @cancleBtn="isDeleteWare = [false, undefined]" @sureBrn="deleteWare(isDeleteWare[1])"></tips-component>
    <!-- 关联出库单-弹窗 -->
    <Modal v-model="add" title="出库单列表" width="80">
      <Table v-if="add" :productList="addListColumns" :productData="addListData" :total="addTotal" :pages="addPages" totalText="条记录" @on-select="onSelect" @on-select-cancel="onSelectionCancel" @on-select-all="onSelectAll" @on-select-all-cancel="onSelectionAllCancel" @change-page="changePage" @on-selection-change="newOnSelect1" ref="customTable">
        <template slot-scope="{ row, index }" slot="product_name">
          <Poptip trigger="hover" :content="row.product_name">
            <p>{{ row.product_name.split(',').splice(0, 2).join(',') }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add = false">取消</span>
      </div>
    </Modal>

    <!-- 关联出库单子列表-弹窗 -->
    <Modal v-model="OutboundorderChildLoading" title="出库单产品列表" width="80">
      <Table v-if="OutboundorderChildLoading" :productList="OutboundorderChildColumns" :productData="OutboundorderChildData" :total="OChildTotal" :pages="OChildPage" @on-selection-change="OChildChange" @on-select-cancel="OChildselectcancel" @on-select-all-cancel="OChildselectcancelall" @change-page="changePageO" totalText="条记录" highlight-row :rowClass="rowClassName"> </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="OChild">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="OutboundorderChildLoading = false">取消</span>
      </div>
    </Modal>

    <!-- 确认删除-弹窗 -->

    <tips-component showModal="delete" v-if="isDeleteSal[0]" @cancleBtn="isDeleteSal = [false, undefined]" @sureBrn="deleteSal(isDeleteSal[1])"></tips-component>
    <!-- 销售退货通知单-弹窗 -->
    <Modal v-model="add1" title="销售退货通知单列表" width="80">
      <Table v-if="add1" :productList="addListColumns1" :productData="addListData1" :total="addTotal1" :pages="addPages1" totalText="条记录" @on-select="onSelect1" @on-select-cancel="onSelectionCancel1" @on-select-all="onSelectAll1" @on-select-all-cancel="onSelectionAllCancel1" @change-page="changePage1" ref="customTable1">
        <template slot-scope="{ row, index }" slot="customer_name">
          <Poptip trigger="hover" :content="row.customer_name">
            <p>{{ row.customer_name.split(',').splice(0, 2).join(',') }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm1">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add1 = false">取消</span>
      </div>
    </Modal>

    <tips-component showModal="delete" v-if="isDeleteSal2[0]" @cancleBtn="isDeleteSal2 = [false, undefined]" @sureBrn="deleteSal2(isDeleteSal2[1])"></tips-component>
    <!-- 关联销售订单-弹窗 -->
    <Modal v-model="add2" title="销售订单列表" width="80">
      <Table v-if="add2" :productList="addListColumns2" :productData="addListData2" :total="addTotal2" :pages="addPages2" totalText="条记录" @on-select="onSelect2" @on-select-cancel="onSelectionCancel2" @on-select-all="onSelectAll2" @on-select-all-cancel="onSelectionAllCancel2" @change-page="changePage2" ref="customTable2">
        <template slot-scope="{ row, index }" slot="product_id_array">
          <Poptip trigger="hover" :content="row.product_id_array.join(',')">
            <p>{{ row.product_id_array[0] }},{{ row.product_id_array[1] ? row.product_id_array[1] : '' }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm2">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add2 = false">取消</span>
      </div>
    </Modal>

    <tips-component showModal="delete" v-if="isDeleteSal3[0]" @cancleBtn="isDeleteSal3 = [false, undefined]" @sureBrn="deleteSal3(isDeleteSal3[1])"></tips-component>
    <!-- 关联销售退货单列表-弹窗 -->
    <Modal v-model="add3" title="销售退货单列表" width="80">
      <Table v-if="add3" :productList="addListColumns3" :productData="addListData3" :total="addTotal3" :pages="addPages3" totalText="条记录" @on-select="onSelect3" @on-select-cancel="onSelectionCancel3" @on-select-all="onSelectAll3" @on-select-all-cancel="onSelectionAllCancel3" @change-page="changePage3" @on-selection-change="newOnSelect2" ref="customTable3">
        <template slot-scope="{ row, index }" slot="product_id_array">
          <Poptip trigger="hover" :content="row.product_id_array.join(',')">
            <p>{{ row.product_id_array[0] }},{{ row.product_id_array[1] ? row.product_id_array[1] : '' }}</p>
          </Poptip>
        </template>
      </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="confirm3">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="add3 = false">取消</span>
      </div>
    </Modal>
    <!-- 关联销售退货单子列表-弹窗 -->
    <Modal v-model="SalesReturnsChildLoading" title="销售退货单产品列表" width="80">
      <Table v-if="SalesReturnsChildLoading" :productList="SalesReturnsChildColumns" :productData="SalesReturnsChildData" :total="SalesTotal" :pages="SalesReturnsPage" totalText="条记录" @on-selection-change="SChildChange" @on-select-cancel="SChildselectcancel" @on-select-all-cancel="SChildselectcancelall" ref="customTable2" @change-page="changePageS" highlight-row :rowClass="rowClassName"> </Table>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="SChild">确定</span>
        <span class="pageBtn finger btnReset marginLeft20" @click="SChildcancal">取消</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import FormUpload from '@/components/formUpload'
import tipsComponent from '@/components/tipsComponent'
import Table from '@/components/table'
export default {
  name: 'addCustomerInvoices',
  components: {
    FormUpload,
    tipsComponent,
    Table,
  },
  data() {
    return {
      // 上传的发票
      debitNote: [],
      /**
       * 关联销售退货单
       * */
      // 弹窗-非全选，false表是非全选状态
      isAll1: false,
      // 弹窗-暂存勾选项
      selectedItem1: [],
      // 弹窗-表格数据
      addListData1: [],
      // 弹窗-表格全部数据
      addListDataAll1: [],
      // 弹窗-表头
      addListColumns1: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '通知单号',
          key: 'id',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '客户名称',
          slot: 'customer_name',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '退货金额',
          key: 'return_price',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.return_price, true))
          },
        },
        {
          title: '数量',
          key: 'return_amount',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '下单时间',
          key: 'create_time',
          minWidth: 100,
          align: 'center',
        },
      ],
      // 总条数
      addTotal1: 0,
      // 弹窗-页码
      addPages1: {
        customer_id: '',
        customer_type: '',

        page: 1,
        rows: 10,
      },
      // 弹窗-关联销售退货单
      add1: false,
      // 回显 销售退货单 选中项
      salesReturnList: [],
      salesList: [],
      /**
       * 关联出库单
       *  */
      // 弹窗-非全选，false表是非全选状态
      isAll: false,
      // 弹窗-暂存勾选项
      selectedItem: [],
      // 弹窗-表格数据
      addListData: [],
      // 弹窗-表格全部数据
      addListDataAll: [],
      // 弹窗-表头
      addListColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '出库单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '客户名称',
          key: 'customer_name',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_name',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'total_number',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 100,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.total_amount, true))
          },
        },
        {
          title: '出库时间',
          key: 'create_time',
          minWidth: 100,
          align: 'center',
        },
        {
          title: '经手人',
          key: 'create_by',
          minWidth: 200,
          align: 'center',
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          render: (h, param) => {
            return h(
              'span',
              {
                style: {
                  // marginLeft: '30px',
                },
                class: 'tableFont',
                on: {
                  click: () => {
                    this.OChildPage.page = 1
                    this.OChildInfo = param.row
                    this.getOutboundorderChild(param.row)
                  },
                },
              },
              '详情'
            )
          },
        },
      ],
      // 弹窗-表头
      addListColumns2: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '订单号',
          key: 'order_code',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_id_array',
          minWidth: 250,
          align: 'center',
        },

        {
          title: '总数量',
          key: 'order_number',
          minWidth: 70,
          align: 'center',
        },
        // {
        //   title: '总金额',
        //   key: 'order_price',
        //   minWidth: 70,
        //   align: 'center',
        // },
        {
          title: '总金额',
          // key: 'order_price',
          minWidth: 70,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.order_price, true))
          },
        },
        {
          title: '下单时间',
          key: 'order_time',
          minWidth: 250,
          align: 'center',
        },
      ],
      // 弹窗-表头
      addListColumns3: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '入库单号',
          key: 'order_number',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '产品',
          key: 'product_name',
          minWidth: 250,
          align: 'center',
        },

        {
          title: '总数量',
          key: 'total_number',
          minWidth: 70,
          align: 'center',
        },
        {
          title: '总金额',
          key: 'total_amount',
          minWidth: 70,
          align: 'center',
        },
        {
          title: '入库时间',
          key: 'order_time',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '操作',
          width: 100,
          align: 'center',
          render: (h, param) => {
            return h(
              'span',
              {
                style: {
                  // marginLeft: '30px',
                },
                class: 'tableFont',
                on: {
                  click: () => {
                    this.SalesReturnsPage.page = 1
                    this.SalesInfo = param.row
                    this.getSalesReturnsChild(param.row)
                  },
                },
              },
              '详情'
            )
          },
        },
      ],
      // 总条数
      addTotal: 0,
      // 弹窗-页码
      addPages: {
        customer_id: '',
        customer_type: '',
        page: 1,
        rows: 10,
      },
      // 弹窗-关联出库单
      add: false,
      // 回显 出库单 选中项
      outboundOrderList: [],

      // 回显客户名称
      echoName: '',
      // 是否删除关联的销售退货单
      isDeleteSal: [false, undefined],
      isDeleteSal2: [false, undefined],
      // 是否删除关联的出库单
      isDeleteWare: [false, undefined],
      // 关联销售退货单
      isDeleteSal3: [false, undefined],
      // 是否提交
      win: false,
      // 限制日期
      limitDate: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now() - 0
        },
      },
      // 发票信息
      invoice: {
        customer_id: undefined, // 客户名称id
        name: '', // 医院/公司名称
        invoice_oddnum: '', // 发票单号
        billing_time: '', // 开票日期
        invoice_money: 0, // 发票金额，
        invoice_info: [],
        invoice_name: [],
        relate_order: [],
        relate_warehousing: [], // 出库单
        relate_order_return: [], // 销售退货单通知单
        relate_sale_order: [], // 关联销售订单
        relate_xt_warehousing: [], // 关联销售退货单 relate_xt_warehousing
      },
      // 发票金额-原始
      invoice_money: 0,
      // 客户名称下拉
      thirdList: [],
      // 发票id
      invoice_id: '',
      // 单号能否改变
      editOddnum: true,
      // 是否只读
      readonly: false,
      selectedItem2: [],
      add2: false,
      addPages2: {
        customer_id: '',
        customer_type: '',
        page: 1,
        rows: 10,
      },
      addListDataAll2: [],
      addListData2: [],
      addTotal2: 0,
      isAll2: false,
      selectedItem3: [],
      add3: false,
      addPages3: {
        customer_id: '',
        customer_type: '',
        page: 1,
        rows: 10,
      },
      addListDataAll3: [],
      addListData3: [],
      addTotal3: 0,
      isAll3: false,
      returnGoods: [],
      // 销售退货单详情
      SalesTotal: 0,
      SalesReturnsPage: {
        order_number: '',
        page: 1,
        rows: 10,
      },
      SalesReturnsChildData: [],
      SalesReturnsChildColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'quantity',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '小计',
          // key: 'amount',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '规格型号',
          key: 'model_name',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '单价',
          // key: 'unit_price',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.unit_price, true))
          },
        },
      ],
      SelectItem: [],
      SeturnGoodsStorage: [],
      SalesReturnsChildID: [],
      SalesReturnsChildLoading: false,
      OselectItem: [],
      OutboundorderStorage: [],
      OutboundorderChildLoading: false,
      OutboundorderChildID: [],
      OChildTotal: 0,
      OChildPage: {
        order_number: '',
        page: 1,
        rows: 10,
      },
      OutboundorderChildData: [],
      OutboundorderChildColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 68,
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 80,
        },
        {
          title: '产品名称',
          key: 'product_name',
          minWidth: 130,
          align: 'center',
        },
        {
          title: '总数量',
          key: 'quantity',
          minWidth: 250,
          align: 'center',
        },
        {
          title: '小计',
          // key: 'amount',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.amount, true))
          },
        },
        {
          title: '规格型号',
          key: 'model_name',
          minWidth: 80,
          align: 'center',
        },
        {
          title: '单价',
          // key: 'unit_price',
          minWidth: 80,
          align: 'center',
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.unit_price, true))
          },
        },
      ],
      order_itemList: [],
      order_itemList2: [],
      SalesInfo: {},
      OChildInfo: {},
    }
  },
  mounted() {
    if (!this.$route.query.added) {
      // 编辑 or 详情
      this.invoice_id = this.$route.query.invoice_id
      this.readonly = this.$route.query.readonly != '0' // true详情
      this.editOddnum = false
      // 获取发票信息
      this.invoiceInformation()
    } else {
      // 新增
      // 客户名称下拉
      this.getCustomerList()
    }
  },
  methods: {
    // 已关联成功的行标红
    rowClassName(row, index) {
      if (row.is_customer_invoices == 1) {
        return 'bgredRow'
      }
      return ''
    },
    // 分页加载
    changePageO(e) {
      this.OChildPage.page = e
      this.getOutboundorderChild(this.OChildInfo)
    },
    // 销售退货单
    changePageS(e) {
      this.SalesReturnsPage.page = e
      this.getSalesReturnsChild(this.SalesInfo)
    },
    // 出库单子列表勾选
    OChildselectcancel(list, row) {
      this.OselectItem = list
      // 取消引
      let ind = 0
      this.OutboundorderStorage.forEach((item, index) => {
        if (row.id == item.id) {
          ind = index
        }
      })
      this.OutboundorderStorage.splice(ind, 1)
    },
    // 出库单子菜单全取消勾选
    OChildselectcancelall(list) {
      this.OselectItem = list
    },
    // 销售退货单子列表
    SChildselectcancel(list, row) {
      this.SelectItem = list
      // 取消引
      let ind = 0
      this.SeturnGoodsStorage.forEach((item, index) => {
        if (row.id == item.id) {
          ind = index
        }
      })
      this.SeturnGoodsStorage.splice(ind, 1)
    },
    // 销售退货子里边全选取消
    SChildselectcancelall(list) {
      this.SelectItem = list
    },
    // 销售退货单取消
    SChildcancal() {
      this.SalesReturnsChildLoading = false
    },
    // 出库单子列表勾选
    newOnSelect1(list) {
      // if (list.length == 0) {
      //   this.OutboundorderChildID = []
      //   return
      // }
      // 存放勾选的订单号

      this.selectedItem = list
      list.forEach((row, index) => {
        this.OChildPage.order_number = row.order_number
        this.$http.get(this.$api.getOutboundorderChild, this.OChildPage, false).then(res => {
          this.OChildChange(res.data.warehous_deliverys, 'push')
        })
      })
    },
    //
    OChildChange(list, type) {
      // 去掉已关联的
      list = list.reduce((cur, next) => {
        if (next.is_customer_invoices != 1) {
          cur.push(next)
        }
        return cur
      }, [])
      if (type) {
        this.OutboundorderChildID.push(...list)
        let obj = {}
        this.OutboundorderChildID = this.OutboundorderChildID.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
        this.OselectItem = list
        this.OutboundorderStorage.push(...list)
        let obj2 = {}
        this.OutboundorderStorage = this.OutboundorderStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj2[next.id]) {
            cur.push(next)
            obj2[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      } else {
        this.OselectItem = list
        this.OutboundorderStorage.push(...list)
        let obj = {}
        this.OutboundorderStorage = this.OutboundorderStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      }
    },
    // 出库单确认
    OChild() {
      if (this.OselectItem.length == this.OutboundorderChildData.length) {
        this.addListData.forEach((item, index) => {
          if (item.order_number == this.OselectItem[0].order_number) {
            this.$set(this.addListData[index], '_checked', true)
            this.selectedItem.push(this.addListData[index])
          }
        })
      } else {
        // 如果选择的长度
        this.addListData.forEach((item, index) => {
          if (item.order_number == this.OutboundorderChildData[0].order_number) {
            this.$set(this.addListData[index], '_checked', false)
            for (let i = 0; i < this.selectedItem.length; i++) {
              if (this.addListData[index].order_number == this.selectedItem[i].order_number) {
                this.selectedItem.splice(i, 1)
                i--
              }
            }
          }
        })
      }
      if (this.OselectItem.length == 0) {
        this.OutboundorderChildData.forEach((item, index) => {
          for (let i = 0; i < this.OutboundorderStorage.length; i++) {
            if (item.id == this.OutboundorderStorage[i].id) {
              this.OutboundorderStorage.splice(i, 1)
              i--
            }
          }
        })
      }
      // 回显外面的勾选项
      this.addListData.forEach((item1, index) => {
        // 遍历 暂存勾选项
        this.selectedItem.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            this.$set(this.addListData[index], '_checked', true)
          }
        })
      })
      this.OutboundorderChildID = JSON.parse(JSON.stringify(this.OutboundorderStorage))
      this.OutboundorderChildLoading = false
    },
    // 出库单详情数据
    getOutboundorderChild(row) {
      this.OutboundorderChildLoading = true
      this.OChildPage.order_number = row.order_number
      this.$http.get(this.$api.getOutboundorderChild, this.OChildPage, false).then(res => {
        this.OutboundorderChildData = res.data.warehous_deliverys
        this.OChildTotal = res.data.total
        // 如果外层点了全选
        this.selectedItem.forEach(item => {
          if (item.order_number == this.OutboundorderChildData[0].order_number) {
            this.OutboundorderChildData.forEach(item2 => {
              item2._checked = true
            })
          }
        })
        // 如果已经选择了就勾选上
        this.OutboundorderChildData.forEach((item, index) => {
          // 禁止选中
          if (item.is_customer_invoices == '1') {
            this.$set(this.OutboundorderChildData[index], '_disabled', true)
          }
          this.OutboundorderChildID.forEach((item2, index2) => {
            if (item.id == item2.id) {
              item._checked = true
            }
          })
        })
      })
    },
    // 销售退货单勾选父级
    newOnSelect2(list) {
      // if (list.length == 0) {
      //   this.SalesReturnsChildID = []
      //   return
      // }
      this.selectedItem3 = list
      list.forEach((row, index) => {
        this.SalesReturnsPage.order_number = row.order_number
        this.$http.get(this.$api.getSalesReturnsChild, this.SalesReturnsPage, false).then(res => {
          this.SChildChange(res.data.xt_lines, 'push')
        })
      })
    },
    // 确认关闭弹窗
    SChild() {
      if (this.SelectItem.length == this.SalesReturnsChildData.length) {
        this.addListData3.forEach((item, index) => {
          if (item.order_number == this.SelectItem[0].order_number) {
            this.$set(this.addListData3[index], '_checked', true)
            this.selectedItem3.push(this.addListData3[index])
          }
        })
      } else {
        // 如果选择的长度
        this.addListData3.forEach((item, index) => {
          if (item.order_number == this.SalesReturnsChildData[0].order_number) {
            this.$set(this.addListData3[index], '_checked', false)
            for (let i = 0; i < this.selectedItem3.length; i++) {
              if (this.addListData3[index].order_number == this.selectedItem3[i].order_number) {
                this.selectedItem3.splice(i, 1)
                i--
              }
            }
          }
        })
      }
      if (this.SelectItem.length == 0) {
        this.SalesReturnsChildData.forEach((item, index) => {
          for (let i = 0; i < this.SeturnGoodsStorage.length; i++) {
            if (item.id == this.SeturnGoodsStorage[i].id) {
              this.SeturnGoodsStorage.splice(i, 1)
              i--
            }
          }
        })
      }
      this.addListData3.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem3.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })
      this.SalesReturnsChildID = JSON.parse(JSON.stringify(this.SeturnGoodsStorage))
      this.SalesReturnsChildLoading = false
    },
    // 勾选项发生改变
    SChildChange(list, type) {
      // 去掉已关联的
      list = list.reduce((cur, next) => {
        if (next.is_customer_invoices != 1) {
          cur.push(next)
        }
        return cur
      }, [])
      if (type) {
        this.SalesReturnsChildID.push(...list)
        let obj = {}
        this.SalesReturnsChildID = this.SalesReturnsChildID.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
        this.SelectItem = list
        this.SeturnGoodsStorage.push(...list)
        let obj2 = {}
        this.SeturnGoodsStorage = this.SeturnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj2[next.id]) {
            cur.push(next)
            obj2[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      } else {
        this.SelectItem = list
        this.SeturnGoodsStorage.push(...list)
        let obj = {}
        this.SeturnGoodsStorage = this.SeturnGoodsStorage.reduce((cur, next) => {
          // id不存即代表该项第一次出现
          if (!obj[next.id]) {
            cur.push(next)
            obj[next.id] = next.id
          }
          // 返回去重后的勾选项
          return cur
        }, [])
      }
    },
    // 销售退货
    getSalesReturnsChild(row) {
      this.SalesReturnsChildLoading = true
      this.SalesReturnsPage.order_number = row.order_number
      this.$http.get(this.$api.getSalesReturnsChild, this.SalesReturnsPage, false).then(res => {
        this.SalesReturnsChildData = res.data.xt_lines
        this.SalesTotal = res.data.total
        // 如果外层点了全选
        this.selectedItem3.forEach(item => {
          if (item.order_number == this.SalesReturnsChildData[0].order_number) {
            this.SalesReturnsChildData.forEach(item2 => {
              item2._checked = true
            })
          }
        })
        // 如果已经选择了就勾选上
        this.SalesReturnsChildData.forEach((item, index) => {
          // 禁止选中
          if (item.is_customer_invoices == '1') {
            this.$set(this.SalesReturnsChildData[index], '_disabled', true)
          }
          this.SalesReturnsChildID.forEach((item2, index2) => {
            if (item.id == item2.id) {
              item._checked = true
            }
          })
        })
      })
    },
    /**
     * 关联销售退货单
     * */
    // 弹窗-确定
    confirm1() {
      this.invoice.relate_order_return = []
      // this.selectedItem1.forEach(item => {
      //   this.salesReturnList.push(item)
      //   this.invoice.relate_order_return.push(item)
      // })
      this.salesReturnList = this.selectedItem1
      this.salesReturnList.forEach(item => {
        this.invoice.relate_order_return.push(item.id)
      })
      // this.calcAmount()
      this.add1 = false
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel1(data) {
      // 清空 暂存勾选项
      this.selectedItem1 = []
      this.isAll1 = false
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-勾全选
    onSelectAll1(data) {
      // 清空 暂存勾选项
      this.selectedItem1 = []
      // 赋值 暂存勾选项
      this.selectedItem1 = this.addListDataAll1
      // this.addListDataAll1.forEach(item => {
      //   this.selectedItem1.push(item.id)
      // })
      // 添加勾选字段
      this.selectedItem1.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll1 = true
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel1(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem1.forEach((item, index) => {
        if (item.id === row.id) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem1.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll1 = false
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-勾单选
    onSelect1(data, row) {
      this.selectedItem1.push(row)
      // 如果 暂存勾选项 的长度等于 关联销售退货单列表 的长度则勾选表头 全选
      this.isAll1 = this.selectedItem1.length == this.addTotal1
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-回显勾选项
    selectedEcho1(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.salesReturnList
      if (!turnPage) {
        this.selectedItem1 = [...this.salesReturnList, ...this.selectedItem1]
      }
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem1 = this.selectedItem1.reduce((cur, next) => {
        // 不存即代表该项第一次出现
        if (!obj[next.id]) {
          cur.push(next)
          obj[next.id] = next.id
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData1.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem1.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.id === item2.id) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 销售退货单列表 的长度则勾选表头 全选
      this.isAll1 = this.selectedItem1.length == this.addTotal1
      this.$nextTick(() => {
        this.$refs.customTable1.$children[0].$children[0].$children[0].currentValue = this.isAll1
      })
    },
    // 弹窗-翻页
    changePage1(e) {
      this.addPages1.page = e
      this.getAddBill1(true)
    },
    // 弹窗-获取 销售退货单列表 部分数据
    getAddBill1(turnPage = false) {
      this.$http.get(this.$api.invoiceOrderReturn, this.addPages1, true).then(res => {
        this.addListData1 = res.data.orders
        this.addTotal1 = res.data.total
        this.addListData1.forEach(item => {
          // 判断客户名称字段
          if (item.hospital_name) {
            item.customer_name = item.hospital_name
          } else {
            item.customer_name = item.dealer_name
          }
          // 处理日期
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho1(turnPage)
      })
    },
    // 弹窗-获取 销售退货单列表 全部数据
    getAddBillAll1() {
      this.addPages1.rows = 1000
      this.$http.get(this.$api.invoiceOrderReturn, this.addPages1).then(res => {
        this.addListDataAll1 = res.data.orders
      })
    },
    // 弹窗-显示
    addBill1() {
      if (!this.invoice.name) {
        this.$Message.warning('请选择 客户名称')
        return
      }
      this.selectedItem1 = []
      // 销售退货单列表
      this.getAddBillAll1()
      this.add1 = true
      this.addPages1.rows = 10
      this.getAddBill1()
    },
    // 弹窗-显示
    addBill2() {
      if (!this.invoice.name) {
        this.$Message.warning('请选择 客户名称')
        return
      }
      this.selectedItem2 = []
      // 销售退货单列表
      this.getAddBillAll2()
      this.add2 = true
      this.addPages2.rows = 10
      this.getAddBill2()
    },
    // 弹窗-获取 销售退货单列表 全部数据
    getAddBillAll2() {
      this.addPages2.rows = 1000
      this.$http.get(this.$api.invoiceSaleList, this.addPages2).then(res => {
        this.addListDataAll2 = res.data.result
      })
    },
    // 弹窗-显示
    addBill3() {
      console.log(this.invoice.name)
      if (!this.invoice.name) {
        this.$Message.warning('请选择 客户名称')
        return
      }
      // this.selectedItem3 = []
      // 销售退货单列表
      this.getAddBillAll3()
      this.add3 = true
      this.addPages3.rows = 10
      this.getAddBill3()
    },
    // 弹窗-获取 销售退货单列表 全部数据
    getAddBillAll3() {
      this.addPages3.rows = 1000
      this.$http.get(this.$api.getCustomerInvoice, this.addPages3,false).then(res => {
        this.addListDataAll3 = res.data.result
      })
    },
    // 弹窗-翻页
    changePage3(e) {
      this.addPages3.page = e
      this.getAddBill3(true)
    },
    // 弹窗-获取 销售退货单列表 部分数据
    getAddBill3(turnPage = false) {
      this.$http.get(this.$api.getCustomerInvoice, this.addPages3, true).then(res => {
        this.addListData3 = res.data.result
        this.addTotal3 = res.data.total
        this.addListData3.forEach(item => {
          // 判断客户名称字段
          if (item.hospital_name) {
            item.customer_name = item.hospital_name
          } else {
            item.customer_name = item.dealer_name
          }
          // 处理日期
          item.order_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho3(turnPage)
      })
    },
    // 弹窗-回显勾选项
    selectedEcho3(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.salesReturnList
      if (!turnPage) {
        this.selectedItem3 = [...this.selectedItem3]
      }
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem3 = this.selectedItem3.reduce((cur, next) => {
        // 不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData3.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem3.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 销售退货单列表 的长度则勾选表头 全选
      this.isAll3 = this.selectedItem3.length == this.addTotal3
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    confirm3() {
      this.invoice.relate_xt_warehousing = []
      // 获取选中的订单号
      let arr = []
      let obj = {}
      arr = this.SalesReturnsChildID.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      this.returnGoods = arr
      this.SalesReturnsChildID.forEach(item => {
        this.invoice.relate_xt_warehousing.push(item.id)
      })
      console.log(this.SalesReturnsChildID, this.invoice.relate_xt_warehousing)
      this.calcAmount()
      this.add3 = false
    },
    // 弹窗-勾单选
    onSelect3(data, row) {
      this.selectedItem3.push(row)
      // 如果 暂存勾选项 的长度等于 关联销售退货单列表 的长度则勾选表头 全选
      this.isAll3 = this.selectedItem3.length == this.addTotal3
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel3(data, row) {
      // 0表触发项的索引
      let foo = 0
      console.log(this.selectedItem3, '222')
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem3.forEach((item, index) => {
        if (item.order_code === row.order_code) {
          foo = index
        }
      })
      // 并且取消内部已勾选项
      for (let i = 0; i < this.SalesReturnsChildID.length; i++) {
        if (this.SalesReturnsChildID[i].order_number == row.order_number) {
          this.SalesReturnsChildID.splice(i, 1)
          i--
        }
      }
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem3.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll3 = false
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    // 弹窗-勾全选
    onSelectAll3(data) {
      // 清空 暂存勾选项
      this.selectedItem3 = []
      // 赋值 暂存勾选项
      this.selectedItem3 = this.addListDataAll3
      // this.addListDataAll1.forEach(item => {
      //   this.selectedItem1.push(item.id)
      // })
      // 添加勾选字段
      this.selectedItem3.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll3 = true
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel3(data) {
      // 清空 暂存勾选项
      this.selectedIte3 = []
      this.isAll3 = false
      this.$nextTick(() => {
        this.$refs.customTable3.$children[0].$children[0].$children[0].currentValue = this.isAll3
      })
    },
    // 弹窗-翻页
    changePage2(e) {
      this.addPages2.page = e
      this.getAddBill2(true)
    },
    // 弹窗-获取 销售退货单列表 部分数据
    getAddBill2(turnPage = false) {
      this.$http.get(this.$api.invoiceSaleList, this.addPages2, true).then(res => {
        this.addListData2 = res.data.result
        this.addTotal2 = res.data.total
        this.addListData2.forEach(item => {
          // 判断客户名称字段
          if (item.hospital_name) {
            item.customer_name = item.hospital_name
          } else {
            item.customer_name = item.dealer_name
          }
          // 处理日期
          item.order_time = item.order_time ? this.$moment(item.order_time * 1000).format('YYYY-MM-DD') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho2(turnPage)
      })
    },
    // 弹窗-回显勾选项
    selectedEcho2(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.salesReturnList
      if (!turnPage) {
        this.selectedItem2 = [...this.salesList, ...this.selectedItem2]
      }
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem2 = this.selectedItem2.reduce((cur, next) => {
        // 不存即代表该项第一次出现
        if (!obj[next.order_code]) {
          cur.push(next)
          obj[next.order_code] = next.order_code
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData2.forEach(item1 => {
        // 遍历 暂存勾选项
        this.selectedItem2.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_code === item2.order_code) {
            item1._checked = true
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 销售退货单列表 的长度则勾选表头 全选
      this.isAll2 = this.selectedItem2.length == this.addTotal2
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    confirm2() {
      this.invoice.relate_sale_order = []
      // this.selectedItem1.forEach(item => {
      //   this.salesReturnList.push(item)
      //   this.invoice.relate_order_return.push(item)
      // })
      this.salesList = this.selectedItem2
      this.salesList.forEach(item => {
        this.invoice.relate_sale_order.push(item.order_code)
      })
      this.calcAmount()
      this.add2 = false
    },
    // 弹窗-勾单选
    onSelect2(data, row) {
      this.selectedItem2.push(row)
      // 如果 暂存勾选项 的长度等于 关联销售退货单列表 的长度则勾选表头 全选
      this.isAll2 = this.selectedItem2.length == this.addTotal2
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel2(data, row) {
      // 0表触发项的索引
      let foo = 0
      console.log(this.selectedItem2, '222')
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem2.forEach((item, index) => {
        if (item.order_code === row.order_code) {
          foo = index
        }
      })
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem2.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll2 = false
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    // 弹窗-勾全选
    onSelectAll2(data) {
      // 清空 暂存勾选项
      this.selectedItem2 = []
      // 赋值 暂存勾选项
      this.selectedItem2 = this.addListDataAll2
      // this.addListDataAll1.forEach(item => {
      //   this.selectedItem1.push(item.id)
      // })
      // 添加勾选字段
      this.selectedItem2.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll2 = true
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel2(data) {
      // 清空 暂存勾选项
      this.selectedIte2 = []
      this.isAll2 = false
      this.$nextTick(() => {
        this.$refs.customTable2.$children[0].$children[0].$children[0].currentValue = this.isAll2
      })
    },
    /**
     * 关联出库单
     * */
    // 弹窗-确定
    confirm() {
      this.invoice.relate_warehousing = []
      // this.outboundOrderList = this.selectedItem
      // 获取选中的订单号
      let arr = []
      let obj = {}
      arr = this.OutboundorderChildID.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.order_number]) {
          cur.push(next)
          obj[next.order_number] = next.order_number
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      this.outboundOrderList = arr
      this.OutboundorderChildID.forEach(item => {
        this.invoice.relate_warehousing.push(item.id)
      })
      this.calcAmount()
      this.add = false
    },
    // 弹窗-取消勾全选
    onSelectionAllCancel(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾全选
    onSelectAll(data) {
      // 清空 暂存勾选项
      this.selectedItem = []
      // 赋值 暂存勾选项
      this.selectedItem = this.addListDataAll
      // 添加勾选字段
      this.selectedItem.forEach(item => {
        item._checked = true
      })
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = true
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-取消勾单选
    onSelectionCancel(data, row) {
      // 0表触发项的索引
      let foo = 0
      // 遍历 暂存勾选项 获取触发项的索引
      this.selectedItem.forEach((item, index) => {
        if (item.id === row.id) {
          foo = index
        }
      })
      // 并且取消内部已勾选项
      for (let i = 0; i < this.OutboundorderChildID.length; i++) {
        if (this.OutboundorderChildID[i].order_number == row.order_number) {
          this.OutboundorderChildID.splice(i, 1)
          i--
        }
      }
      // 从 暂存勾选项 中删除该触发项
      this.selectedItem.splice(foo, 1)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = false
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-勾单选
    onSelect(data, row) {
      this.selectedItem.push(row)
      // 如果 暂存勾选项 的长度等于 关联出库单列表 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-回显勾选项
    selectedEcho(turnPage = false) {
      // 需要勾选的项
      // turnPage为 true 时表正在翻页，不要连接 this.outboundOrderList
      if (!turnPage) {
        console.log(this.outboundOrderList)
        this.selectedItem = [...this.selectedItem]
      }
      console.log(this.selectedItem, '数字')
      // 去重
      // 存放唯一id作为去重的判断条件
      let obj = {}
      this.selectedItem = this.selectedItem.reduce((cur, next) => {
        // id不存即代表该项第一次出现
        if (!obj[next.id]) {
          cur.push(next)
          obj[next.id] = next.id
        }
        // 返回去重后的勾选项
        return cur
      }, [])
      // 遍历表格当前页
      this.addListData.forEach((item1, index) => {
        // 遍历 暂存勾选项
        this.selectedItem.forEach(item2 => {
          // 表格当前页要勾选的项
          if (item1.order_number === item2.order_number) {
            this.$set(this.addListData[index], '_checked', true)
          }
        })
      })
      // 如果 暂存勾选项 的长度等于 出库单列表 的长度则勾选表头 全选
      this.isAll = this.selectedItem.length == this.addTotal
      this.$nextTick(() => {
        this.$refs.customTable.$children[0].$children[0].$children[0].currentValue = this.isAll
      })
    },
    // 弹窗-翻页
    changePage(e) {
      this.addPages.page = e
      this.getAddBill(true)
    },
    // 弹窗-获取 出库单 部分数据
    getAddBill(turnPage = false) {
      this.$http.get(this.$api.invoiceWarehouseList, this.addPages, true).then(res => {
        this.addListData = res.data.deliverys
        this.addTotal = res.data.total
        this.addListData.forEach(item => {
          // 判断客户名称字段
          if (item.hospital_name) {
            item.customer_name = item.hospital_name
          } else {
            item.customer_name = item.dealer_name
          }
          // 处理日期
          item.create_time = item.create_time ? this.$moment(item.create_time * 1000).format('YYYY-MM-DD') : ''
        })
        // 回显勾选项 turnPage为 true 表正在翻页
        this.selectedEcho(turnPage)
      })
    },
    // 弹窗-获取 出库单 全部数据
    getAddBillAll() {
      this.addPages.rows = 1000
      this.$http.get(this.$api.invoiceWarehouseList, this.addPages).then(res => {
        this.addListDataAll = res.data.deliverys
      })
    },
    // 弹窗-显示
    addBill() {
      if (!this.invoice.name) {
        this.$Message.warning('请选择 客户名称')
        return
      }
      // this.selectedItem = []
      // 出库单
      this.getAddBillAll()
      this.add = true
      this.addPages.rows = 10
      this.getAddBill()
    },

    // 计算金额
    calcAmount() {
      this.invoice.invoice_money = 0
      this.OutboundorderChildID.forEach(item => {
        this.invoice.invoice_money += item.amount
      })
      this.SalesReturnsChildID.forEach(item => {
        this.invoice.invoice_money -= item.amount
      })
      // this.salesList.forEach(item => {
      //   this.invoice.invoice_money += item.order_price
      // })
      this.invoice.invoice_money = this.$utils.formatMoney(this.invoice.invoice_money)
    },
    // 删除销售退货通知单
    deleteSal(index) {
      if(!this.$route.query.added) {
        let  arr = []
        // 编辑删除的时候需要调接口
        arr.push(this.invoice.relate_order_return[index])
        let obj = {
          document_type: '8',
          document_list: arr,
          invoice_id: this.$route.query.invoice_id
        }
        this.$http.put(this.$api.removeinvoice,obj,false).then(res => {

        })
      }
      this.salesReturnList.splice(index, 1)
      this.invoice.relate_order_return.splice(index, 1)
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeleteSal = [false, undefined]
    },
    // 删除销售订单
    deleteSal2(index) {
      if(!this.$route.query.added) {
        let arr = []
        // 编辑删除的时候需要调接口
        arr.push(this.invoice.relate_sale_order[index])
        let obj = {
          document_type: '7',
          document_list: arr,
          invoice_id: this.$route.query.invoice_id
        }
        this.$http.put(this.$api.removeinvoice,obj,false).then(res => {

        })
      }
      this.salesList.splice(index, 1)
      this.invoice.relate_sale_order.splice(index, 1)
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeleteSal2 = [false, undefined]
    },
    // 删除出库单
    deleteWare(index) {
      this.invoice.relate_warehousing = []
      let arr = []
      // 删除出库子产品
      for (var i = 0; i < this.OutboundorderChildID.length; i++) {
        if (this.OutboundorderChildID[i].order_number == this.outboundOrderList[index].order_number) {
          arr.push(this.OutboundorderChildID[i].id)
          this.OutboundorderChildID.splice(i, 1)
          i--
        }
      }

      // 删除暂存
      for (var i = 0; i < this.OutboundorderStorage.length; i++) {
        if (this.OutboundorderStorage[i].order_number == this.outboundOrderList[index].order_number) {
          this.OutboundorderStorage.splice(i, 1)
          i--
        }
      }
      // 删除勾选项
      for (let i = 0; i < this.selectedItem.length; i++) {
        if (this.selectedItem[i].order_number == this.outboundOrderList[index].order_number) {
          this.selectedItem.splice(i, 1)
          i--
        }
      }
      this.outboundOrderList.splice(index, 1)
      this.OutboundorderChildID.forEach(item => {
        this.invoice.relate_warehousing.push(item.id)
      })
      if(!this.$route.query.added) {
        // 编辑删除的时候需要调接口
        let obj = {
          document_type: '3',
          document_list: arr,
          invoice_id: this.$route.query.invoice_id
        }
        this.$http.put(this.$api.removeinvoice,obj,false).then(res => {

        })
      }
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeleteWare = [false, undefined]
    },
    // 删除销售退货单
    deleteSal3(index) {
      //  return
      this.invoice.relate_warehousing = []
      // this.invoice.relate_xt_warehousing.splice(index, 1)
      let arr = []
      // 删除出库子产品
      for (var i = 0; i < this.SalesReturnsChildID.length; i++) {
        if (this.SalesReturnsChildID[i].order_number == this.returnGoods[index].order_number) {
          arr.push(this.SalesReturnsChildID[i].id)
          this.SalesReturnsChildID.splice(i, 1)
          i--
        }
      }
      // 删除暂存
      for (var i = 0; i < this.SeturnGoodsStorage.length; i++) {
        if (this.SeturnGoodsStorage[i].order_number == this.returnGoods[index].order_number) {
          this.SeturnGoodsStorage.splice(i, 1)
          i--
        }
      }
      // 删除勾选项
      for (let i = 0; i < this.selectedItem3.length; i++) {
        if (this.selectedItem3[i].order_number == this.returnGoods[index].order_number) {
          this.selectedItem3.splice(i, 1)
          i--
        }
      }
      this.returnGoods.splice(index, 1)
      this.SalesReturnsChildID.forEach(item => {
        this.invoice.relate_xt_warehousing.push(item.id)
      })
      if(!this.$route.query.added) {
        // 编辑删除的时候需要调接口
        let obj = {
          document_type: '4',
          document_list: arr,
          invoice_id: this.$route.query.invoice_id
        }
        this.$http.put(this.$api.removeinvoice,obj,false).then(res => {

        })
      }
      this.$Message.success('删除成功')
      this.calcAmount()
      this.isDeleteSal3 = [false, undefined]
    },
    // 客户名称改变
    selectName(foo) {
      // 出库单
      this.outboundOrderList = []
      this.invoice.relate_warehousing = []
      // 销售退货单通知单
      this.salesReturnList = []
      this.invoice.relate_order_return = []
      // 销售订单
      this.salesList = []
      // 销售退货单
      this.returnGoods = []
      if (!foo) {
        this.invoice.name = ''
        this.invoice.customer_id = ''
        // 出库单
        this.addPages.customer_id = ''
        this.addListData = []
        this.addListDataAll = []
        // 销售退货单
        this.addPages1.customer_id = ''
        this.addListData1 = []
        this.addListDataAll1 = []
        // 销售订单
        this.addPages2.customer_id = ''
        this.addListData2 = []
        this.addListDataAll2 = []
        return
      }
      foo = JSON.parse(foo)
      for (let item of this.thirdList) {
        if (item.customer_id == foo.customer_id && item.customer_name == foo.customer_name && item.customer_type == foo.customer_type) {
          this.invoice.customer_id = foo.customer_id
          this.invoice.name = foo.customer_name
          // 出库单
          this.addPages.customer_id = foo.customer_id
          this.addPages.customer_type = foo.customer_type
          // 销售退货通知单
          this.addPages1.customer_id = foo.customer_id
          this.addPages1.customer_type = foo.customer_type
          // 销售订单
          this.addPages2.customer_id = foo.customer_id
          this.addPages2.customer_type = foo.customer_type
          // 销售退货单
          this.addPages3.customer_id = foo.customer_id
          this.addPages3.customer_type = foo.customer_type
        }
      }
    },
    // 提交前校验
    beforeCommit() {
      console.log(this.OutboundorderChildID, this.SalesReturnsChildID)
      if (!this.invoice.name) {
        this.$Message.warning('客户名称不能为空')
        return
      }
      if (!this.invoice.billing_time) {
        this.$Message.warning('开票时间不能为空')
        return
      }
      if (this.invoice.relate_warehousing.length == 0 && this.invoice.relate_xt_warehousing.length == 0) {
        this.$Message.warning('出库单 或 销售退货单不能为空')
        return
      }
      this.win = true
    },
    // 删除图片
    deleteImage(index) {
      this.debitNote.splice(index, 1)
    },
    // 图片查看
    openFile(url) {
      window.open(url)
    },
    // 获取文件上传成功后的 key 值
    getKey(key, file_name, url) {
      this.debitNote.push({
        invoice_key: key,
        invoice_url: url,
        invoice_name: file_name,
      })
    },
    // 提交
    commit() {
      console.log(this.invoice)
      this.win = false
      this.invoice.relate_warehousing = !this.invoice.relate_warehousing || this.invoice.relate_warehousing.length != 0 ? this.invoice.relate_warehousing : []
      this.invoice.relate_order_return = !this.invoice.relate_order_return || this.invoice.relate_order_return.length != 0 ? this.invoice.relate_order_return : []
      this.invoice.relate_sale_order = !this.invoice.relate_sale_order || this.invoice.relate_sale_order.length != 0 ? this.invoice.relate_sale_order : []
      this.invoice.relate_xt_warehousing = !this.invoice.relate_xt_warehousing || this.invoice.relate_xt_warehousing.length != 0 ? this.invoice.relate_xt_warehousing : []
      console.log(this.invoice)
      // return
      // 整理发票信息
      if (this.debitNote.length != 0) {
        this.invoice.invoice_keys = []
        this.debitNote.forEach(item => {
          this.invoice.invoice_keys.push({
            invoice_name: item.invoice_name,
            invoice_key: item.invoice_key,
          })
        })
      } else {
        delete this.invoice.invoice_keys
      }
      console.log(this.invoice.invoice_keys, '信息')
      // 千分位转数值
      this.invoice.invoice_money = this.invoice.invoice_money.replace(/,/gi, '')
      if (!this.$route.query.added) {
        // 编辑
        this.invoice.invoice_id = this.invoice_id
        this.$http.put(this.$api.invoiceManage, this.invoice).then(res => {
          if (res.status) {
            this.$Message.success('提交成功')
            this.$router.replace({
              name: 'invoiceManage',
              params: {
                index: '2',
              },
            })
          }
        })
      } else {
        // 新增
        this.$http.post(this.$api.invoiceManage, this.invoice).then(res => {
          if (res.status) {
            this.$Message.success('新增成功')
            this.$router.replace({
              name: 'invoiceManage',
              params: {
                index: '2',
              },
            })
          }
        })
      }
      sessionStorage.setItem('updataCache', '0')
    },
    // 选择日期
    changeTime(e) {
      this.invoice.billing_time = e
    },
    // 返回
    back() {
      this.$router.replace({
        name: 'invoiceManage',
        params: {
          index: '2',
        },
      })
    },
    // 获取客户名称下拉列表
    getCustomerList() {
      this.$http.get(this.$api.customerList).then(res => {
        // 获取客户名称列表
        this.thirdList = res.data
        if (!this.$route.query.added) {
          // 编辑 or 详情
          for (let item of this.thirdList) {
            if (item.customer_id == this.invoice.customer_id && item.customer_name == this.invoice.name) {
              // 生成请求出库单的参数
              this.addPages.customer_id = item.customer_id
              this.addPages.customer_type = item.customer_type
              // 生成请求销售退货单的参数
              this.addPages1.customer_id = item.customer_id
              this.addPages1.customer_type = item.customer_type

              this.addPages2.customer_id = item.customer_id
              this.addPages2.customer_type = item.customer_type

              this.addPages3.customer_id = item.customer_id
              this.addPages3.customer_type = item.customer_type
              // 回显客户名称
              this.echoName = JSON.stringify(item)
            }
          }
        }
      })
    },
    // 获取发票信息
    invoiceInformation() {
      this.$http.get(this.$api.invoiceInformation, { invoice_id: this.invoice_id }, true).then(res => {
        this.invoice = res.data
        console.log(res.data, '获取发票信息', this.invoice)
        this.invoice.invoice_money = this.$utils.formatMoney(this.invoice.invoice_money)
        this.invoice_money = this.invoice.invoice_money
        this.invoice.relate_order_return = this.invoice.relate_order_return || []
        if (this.invoice.invoice_keys) {
          this.debitNote.push(this.invoice.invoice_keys)
          this.invoice.invoice_keys.forEach((item, index) => {
            item.invoice_url = this.invoice.invoice_urls[index]
          })
        }
        this.debitNote = this.invoice.invoice_keys
        // 处理日期
        this.invoice.billing_time = this.invoice.billing_time ? this.$moment(this.invoice.billing_time * 1000).format('YYYY-MM-DD') : ''

        // 处理销售退货单
        res.data.relate_order_return.forEach(item => {
          this.salesReturnList.push({
            id: item,
          })
        })
        if (res.data.relate_sale_order) {
          res.data.relate_sale_order.forEach(item => {
            this.salesList.push({
              order_code: item,
            })
          })
        }
        this.invoice.relate_warehousing = this.invoice.relate_warehousing || []

        // 处理出库单
        res.data.relate_warehousing.forEach(item => {
          this.outboundOrderList.push(item)
        })
        this.invoice.relate_warehousing = []
        this.outboundOrderList.forEach(item => {
          item.line_ids.forEach(item2 => {
            this.OutboundorderChildID.push({
              order_number: item.order_number,
              id: item2.id,
              amount: item2.amount,
            })
            this.OutboundorderStorage.push({
              order_number: item.order_number,
              id: item2.id,
              amount: item2.amount,
            })
            this.invoice.relate_warehousing.push(item2.id)
          })
        })
        // 销售退货单
        if (res.data.relate_xt_warehousing) {
          res.data.relate_xt_warehousing.forEach(item => {
            this.returnGoods.push(item)
          })
        }
        this.invoice.relate_xt_warehousing = []
        this.returnGoods.forEach(item => {
          item.line_ids.forEach(item2 => {
            this.SalesReturnsChildID.push({
              order_number: item.order_number,
              id: item2.id,
              amount: item2.amount,
            })
            this.SeturnGoodsStorage.push({
              order_number: item.order_number,
              id: item2.id,
              amount: item2.amount,
            })
            this.invoice.relate_xt_warehousing.push(item2.id)
          })
        })
        console.log(this.SalesReturnsChildID, this.OutboundorderChildID, this.invoice.relate_warehousing)
        // this.invoice.relate_order_return = []
        // this.salesReturnList.forEach(item => {
        //   this.invoice.relate_order_return.push(item)
        // })
        // 客户名称下拉
        this.getCustomerList()
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  position: relative;

  .table {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #525b6d;

    .item {
      display: flex;
      height: 50px;
      line-height: 50px;
      border: 1px solid #e8eaec;
      border-bottom: 0;

      .title {
        width: 200px;
        padding-left: 30px;
        background-color: #f8f8f9;
        border-right: 1px solid #e8eaec;
      }

      .body {
        flex: 1;
        padding-left: 20px;
        display: flex;
        align-items: center;
        .uploading {
          height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          padding-left: 7px;
          .tip {
            font-size: 14px;
            padding: 0 0 0 4px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .upload {
            width: 52px;
            height: 100%;
            background-color: #f4f4f4;
            border-left: 1px solid #e3e3e3;
            float: right;
            text-align: center;
            font-size: 14px;
          }

          span {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin-right: 10px;
            cursor: pointer;

            a {
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            i {
              font-style: normal;
              padding-left: 10px;
            }
          }
        }
        .width300 {
          width: 300px;
        }
        .width1000 {
          width: 1000px;
        }
      }
    }

    .newItem {
      display: flex;
      border: 1px solid #e8eaec;
      border-bottom: 0;
      .title {
        min-height: 50px;
        width: 200px;
        padding-left: 30px;
        background: #f8f8f9;
        border-right: 1px solid #e8eaec;
        display: flex;
        align-items: center;
      }
      .body {
        padding-left: 20px;
        display: flex;
        align-items: center;
        margin: 7px 0;
        .width1000 {
          width: 1000px;
        }
        .uploading {
          min-height: 36px;
          line-height: 36px;
          border: 1px solid #e3e3e3;
          border-radius: 4px;
          font-size: 14px;
          position: relative;
          padding-left: 7px;

          display: flex;
          flex-wrap: wrap;

          .tip {
            font-size: 14px;
            padding: 0 0 0 4px;
            color: #c5c8ce;
            cursor: default;
            user-select: none;
          }
          .upload {
            width: 52px;
            height: 100%;
            background-color: #f4f4f4;
            border-left: 1px solid #e3e3e3;
            float: right;
            text-align: center;
            font-size: 14px;
          }
          .itemSpan {
            border: 1px solid #dddddd;
            border-radius: 13px;
            padding: 1px 10px;
            margin: 3px 10px 3px 0;
            // cursor: pointer;
            span {
              border: 0;
              text-decoration: none;
              color: #333333;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            i {
              font-style: normal;
              padding-left: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .borderBtm {
      border-bottom: 1px solid #e8eaec;
    }
  }

  .pageBtm {
    margin-top: 20px;
  }
}
</style>
